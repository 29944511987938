<template>
	<div class="tours-sidebar">
		<div class="tours-caption">
			<router-link :to="getCatUrl(undefined)">Все туры</router-link>
		</div>
		<div v-for="(cat, key) in categories" class="tour-cat">
			<router-link
				:to="getCatUrl(key)"
				:class="checkActive(key) ? 'active' : ''"
			>{{ cat }}</router-link>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				categories: {
					oneday: 'Однодневные',
					weekend: 'Тур выходного дня',
					holiday: 'Праздничные туры',
					may: 'Майские праздники',
					independence: 'День независимости',
					school: 'Туры для школьников',
					carpathians: 'Тур в карпаты',
					lviv: 'Тур во Львов',
					skiing: 'Горнолыжные туры',
					chernobyl: 'Тур в Чернобыль',
					piligrim: 'Паломнические туры'
				}
			};
		},
		methods: {
			checkActive(key) {
				return key === this.$route.query.sideCat;
			},
			getCatUrl(cat) {
				let route = this.getExtendedUrl({sideCat: cat});
				route.path = this.$route.name === 'ToursCalendar' ? '/tours/calendar' : '/tours';
				return route;
			}
		}
	}
</script>

<style lang="less">
	@import "../styles/helpers";

	.tours-sidebar {
		position: absolute;
		width: @sidebarWidth;
		//margin-left: 10px;
		border-right: 1px solid #ccc;
		padding: 5px 0;
		display: none;
		min-height: 100%;

		.tours-caption {
			text-align: center;
			font-size: 18px;
			padding: 10px 0;
			border-bottom: 1px solid #ccc;

			a {
				color: #000;
				text-decoration: none;
			}
		}

		.tour-cat {
			a {
				display: inline-block;
				width: 100%;
				padding: 10px 5px 10px 25px;
				color: #000;
				font-size: 14px;
				text-decoration: none;

				&:hover, &.active {
					background: #ededed;
				}
			}
		}

		@media (min-width: 1000px) {
			display: block;
		}
	}
</style>