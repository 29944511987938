<template>
	<div class="tours-view-tabs">
		<router-link
			:to="getUrl('/tours')"
			:class="getClass('/tours')"
		>Список</router-link>
		<router-link
			:to="getUrl('/tours/calendar', true)"
			:class="getClass('/tours/calendar')"
		>Календарь</router-link>
	</div>
</template>

<script>
	export default {
		methods: {
			getUrl(path, removeSideCat = false) {
				let query = {...this.$route.query};
				if (removeSideCat) {
					delete query.sideCat;
				}
				return {path, query};
			},
			getClass(path) {
				return path === this.$route.path ? 'link-active' : '';
			}
		}
	}
</script>

<style lang="less">
	.tours-view-tabs {
		padding: 30px 0 20px 0;
		text-align: center;
		border-bottom: 1px solid #ccc;

		a {
			display: inline-block;
			padding: 5px 10px;
			text-decoration: none;
			border: 1px solid #ccc;
			color: #000;

			&:first-child {
				border-right: none;
			}

			&.link-active, &:hover {
				background: #efefef;
			}
		}
	}
</style>