<template>
	<div class="tours">
		<bread-crumbs :items="breadcrumbs"></bread-crumbs>

		<tour-search-form></tour-search-form>

		<tours-view-tabs></tours-view-tabs>

		<tours-sidebar></tours-sidebar>

		<div class="tours-wr">
			<tours-list :tours="tours"></tours-list>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';

	export default {
		data() {
			return {
				tours: null
			};
		},
		computed: {
			breadcrumbs() {
				return [
					{
						url: '/',
						text: 'Главная'
					},
					{
						text: 'Туры'
					}
				];
			}
		},
		methods: {
			async loadTours() {
				let params = this.$route.query;
				let res = await axios('/api/tours', { params });
				this.tours = res.data.res;
			}
		},
		watch: {
			$route() {
				this.loadTours();
			}
		},
		mounted() {
			this.loadTours();
		}
	}
</script>

<style lang="less">
@import "../styles/vars";

.tours {
	.search-form {
		margin-top: 20px;
	}

	.tours-wr {
		margin: 50px auto;
		width: 100%;
		@media (min-width: 1000px) {
			padding-left: @sidebarWidth;
		}
	}
}
</style>