<template>
	<div class="breadcrumbs">
		<template v-for="(item, i) in items">
			<router-link v-if="item.url" :to="item.url">{{ item.text }}</router-link>
			<span v-else>{{ item.text }}</span>
			<span v-if="i < items.length - 1" class="arrow">&gt;</span>
		</template>
	</div>
</template>

<script>
	export default {
		props: ['items']
	}
</script>

<style lang="less">
	@import "../styles/helpers";

	.breadcrumbs {
		width: 800px;
		max-width: 95%;
		margin: 15px auto;
		font-size: 15px;

		a {
			text-decoration: none;
			color: @primColor;

			&:hover {
				text-decoration: underline;
			}
		}

		.arrow {
			margin: 0 6px;
		}
	}
</style>