import moment from 'moment';
import axios from 'axios';

const curTime = moment();

export default {
	data() {
		return {
			trips: null,
			weekDays: [
				'Пн',
				'Вт',
				'Ср',
				'Чт',
				'Пт',
				'Сб',
				'Вс'
			],
			months: [
				'Январь',
				'Февраль',
				'Март',
				'Апрель',
				'Май',
				'Июнь',
				'Июль',
				'Август',
				'Сентябрь',
				'Октябрь',
				'Ноябрь',
				'Декабрь'
			],
			clickedCell: null
		};
	},
	computed: {
		breadcrumbs() {
			return [
				{
					url: '/',
					text: 'Главная'
				},
				{
					text: 'Календарь туров'
				}
			];
		},
		curYear() {
			return curTime.format('YYYY');
		},
		yearMonth() {
			return this.$route.query.month || curTime.format('YYYY-MM');
		},
		grid() {
			let yearMonth = this.yearMonth;
			let time = moment(yearMonth + '-01');
			let dayOfWeek = time.day() - 1;
			if (dayOfWeek < 0) {
				dayOfWeek = 6;
			}
			let daysInMonth = time.daysInMonth();

			let grid = [];
			let rowsCnt = Math.ceil((daysInMonth + dayOfWeek) / 7);
			for (let i = 0; i < rowsCnt; i++) {
				let row = [];
				for (let j = 0; j < 7; j++) {
					let cell = null;
					if (i > 0 || j >= dayOfWeek) {
						let day = i * 7 + j - dayOfWeek + 1;
						let date = yearMonth + '-' + (day < 10 ? '0' : '') + day;
						if (day <= daysInMonth) {
							let events = [];
							if (this.events) {
								events = this.events.filter(event => event.remindDate === date);
							}
							let isToday = (date === this.curDate);
							let isPrevious = (date < this.curDate);
							cell = {day, date, events, isToday, isPrevious};
						}
					}
					row.push(cell);
				}
				grid.push(row);
			}

			return grid;
		},
		tripDatesMap() {
			if (!this.trips) return {};

			let map = {};
			this.trips.forEach(trip => {
				if (!map[trip.date]) {
					map[trip.date] = [];
				}
				map[trip.date].push(trip);
			});
			return map;
		}
	},
	methods: {
		async loadTrips() {
			this.trips = null;

			let params = this.$route.query;
			let res = await axios('/api/trips', { params });
			this.trips = res.data.res;
		},
		getDayNum(cell) {
			return cell.date.replace(/.*?([1-9]?[0-9])$/, '$1');
		},
		requestTour(tourId, date) {
			this.app.requestPopup = { tourId, date };
		},
		getSiblingMonth(monthInc) {
			return moment(this.yearMonth).add(monthInc, 'months').format('YYYY-MM');
		},
		getSiblingMonthUrl(monthInc) {
			let yearMonth = this.getSiblingMonth(monthInc);
			let query = { ...this.$route.query, month: yearMonth };
			return { query };
		},
		getHumanMonth(monthInc = 0) {
			let yearMonth = this.getSiblingMonth(monthInc);
			let [y, m] = yearMonth.split('-');
			let monthFull = this.months[parseInt(m) - 1];
			if (y !== this.curYear) {
				monthFull += ', ' + y;
			}
			return monthFull;
		},
		showDayTrips(cell) {
			this.clickedCell = cell;
		}
	},
	watch: {
		$route() {
			this.loadTrips();
		}
	},
	mounted() {
		this.loadTrips();
	}
}