<template>
	<div class="tour-box">
		<bread-crumbs :items="breadcrumbs"></bread-crumbs>

		<div v-if="tour" class="tour-inner">
			<h1>{{ tour.title }}</h1>

			<div class="tour-photo">
				<carousel :slides="slides" interval="5000"></carousel>
			</div>

			<div class="tour-section" :class="!sectionsShown.details ? 'tour-section-collapsed' : ''">
				<div class="tour-section-header" @click="toggleSection('details')">
					<h2>Детали тура</h2>
				</div>
				<div class="tour-section-content">
					<table class="details-tbl">
						<tbody>
						<tr>
							<td>
								<strong>Цена:</strong>
							</td>
							<td>
								От {{ tour.price }} грн
							</td>
						</tr>
						<tr>
							<td>
								<strong>Продолжительность:</strong>
							</td>
							<td>
								{{ tour.days }} дн.
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>

			<div class="tour-section" :class="!sectionsShown.desc ? 'tour-section-collapsed' : ''">
				<div class="tour-section-header" @click="toggleSection('desc')">
					<h2>Описание</h2>
				</div>
				<div class="tour-section-content">
					<div class="tour-desc" :class="descCollapsed ? 'tour-desc-collapsed' : ''">
						<div v-html="htmlContent" class="tour-desc-text"></div>
						<span v-if="descCollapsed" class="expand-link" @click="descCollapsed = false">Подробнее</span>
					</div>
				</div>
			</div>

			<div class="tour-section" :class="!sectionsShown.dates ? 'tour-section-collapsed' : ''">
				<div class="tour-section-header" @click="toggleSection('dates')">
					<h2>Оформить заявку</h2>
				</div>
				<div class="tour-section-content">
					<div class="dates">
						<span
							v-for="date in tour.dates"
							class="date"
							@click="app.requestTour($route.params.tourId, date)"
						>
							{{ dateToHuman(date, true) }}
						</span>
					</div>
				</div>
			</div>

			<div class="tour-section" :class="!sectionsShown.comments ? 'tour-section-collapsed' : ''">
				<div class="tour-section-header" @click="toggleSection('comments')">
					<h2>Комментарии</h2>
				</div>
				<div class="tour-section-content">
					<div class="comments">
						<div v-if="tour.comments">
							<template v-for="(comment, i) in tour.comments">
								<div v-if="i < commentsMax" class="comment">
									<div class="comment-author">
										<strong>Имя:</strong>
										{{ comment.author }}
									</div>
									<div class="comment-time">
										<strong>Дата:</strong>
										{{ dateToHuman(tsToDate(comment.time)) }}
									</div>
									<div class="comment-text">{{ comment.text }}</div>
								</div>
							</template>
							<div v-if="tour.comments.length > commentsMax" class="more-box">
								<span @click="showMoreComments">Показать еще</span>
							</div>
						</div>
					</div>

					<div class="send-comment-box">
						<h3>Оставить комментарий</h3>
						<div>
							<i class="text-field-icon fa fa-fw fa-user"></i>
							<input type="text" v-model="name" class="text-field" placeholder="Ваше имя">
						</div>
						<div>
							<i class="text-field-icon fa fa-fw fa-envelope-o"></i>
							<input type="text" v-model="email" class="text-field" placeholder="Ваш e-mail">
						</div>
						<div>
							<i class="text-field-icon fa fa-fw fa-comment"></i>
							<textarea class="comment-textarea text-area" v-model="comment" placeholder="Комментарий"></textarea>
						</div>
						<div v-if="commentStatus === 'DEFAULT'">
							<rich-btn @click="sendComment">Отправить</rich-btn>
						</div>
						<div v-else-if="commentStatus === 'SENDING'">Отправляется...</div>
						<div v-else-if="commentStatus === 'SENT'">Отзыв отправлен. Он будет опубликован на сайте после модерации</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import marked from 'marked';
import moment from 'moment';

export default {
	data() {
		return {
			tour: null,
			title: null,
			htmlContent: null,
			commentsMax: 3,

			name: '',
			email: '',
			comment: '',
			commentStatus: 'DEFAULT',

			sectionsShown: {
				details: true,
				desc: true,
				dates: true,
				comments: true
			},
			descCollapsed: false
		};
	},
	computed: {
		breadcrumbs() {
			return [
				{
					url: '/',
					text: 'Главная'
				},
				{
					url: '/tours',
					text: 'Туры'
				},
				{
					text: this.tour && this.tour.title
				}
			];
		},
		slides() {
			if (!this.tour) return null;

			return this.tour.photos.map(img => {
				return { img };
			});
		}
	},
	methods: {
		async loadInfo() {
			let params = {
				tourId: this.$route.params.tourId
			};
			let res = await axios('/api/tour', { params });
			this.tour = res.data.res;

			this.ensureCorrectPath();

			this.htmlContent = marked(this.tour.markdown);
		},
		ensureCorrectPath() {
			let correctPath = '/tour/' + this.tour.id;
			if (this.tour.translit) {
				correctPath += '/' + this.tour.translit;
			}
			if (this.$route.path === correctPath) return;

			this.$router.replace({ path: correctPath, query: this.$route.query });
		},
		showMoreComments() {
			this.commentsMax += 10;
		},
		async sendComment() {
			if (!this.name) return this.alert('Пожалуйста, введите Ваше имя');
			if (!this.comment) return this.alert('Пожалуйста, введите отзыв');

			this.commentStatus = 'SENDING';

			let body = {
				tourId: this.$route.params.tourId,
				name: this.name,
				email: this.email,
				comment: this.comment,
			};
			await axios.post('/api/tour/send-comment', body);

			this.commentStatus = 'SENT';
		},
		getTime(ts) {
			return moment(ts * 1000).format('D MMM, YYYY');
		},
		getFormattedDate(date) {
			return moment(date, 'YYYY-MM-DD').format('D MMM, YYYY');
		},
		toggleSection(section) {
			this.sectionsShown[section] = !this.sectionsShown[section];
		}
	},
	mounted() {
		this.loadInfo();
	}
}
</script>

<style lang="less" src="./Tour.less"></style>