<template>
	<span class="rich-btn">
		<button
			:type="btnType"
			:style="styleMap"
			:class="getClasses()"
			:disabled="disabled || loading"
			@click="onClick"
		>
			<i v-if="icon && !iconPosition" :class="iconClass"></i>
			<slot></slot>
			<i v-if="icon && iconPosition === 'right'" :class="iconClass"></i>
		</button>
	</span>
</template>

<script>
	export default {
		props: ['type', 'hidden', 'disabled', 'loading', 'icon', 'icon-position', 'min-width', 'color'],
		data() {
			return {
				title: null,
				filesCnt: 0,
				fileAvailable: true
			};
		},
		computed: {
			btnType() {
				return this.type || 'button';
			},
			iconClass() {
				if (this.loading) return 'fa fa-fw fa-spinner spin-fast';
				return 'fa fa-fw fa-' + this.icon;
			},
			styleMap() {
				let map = {};
				if (this.minWidth !== undefined) {
					let w = this.minWidth;
					if (!isNaN(w)) {
						w += 'px';
					}
					map.minWidth = w;
				}
				return map;
			}
		},
		methods: {
			getClasses() {
				let COLORS = ['yellow', 'green', 'orange', 'red', 'transparent', 'soft-red', 'grey'];
				let SIZES = ['normal', 'small'];

				let color = COLORS.indexOf(this.color) !== -1 ? this.color : COLORS[0];
				let size = SIZES.indexOf(this.size) !== -1 ? this.size : SIZES[0];

				let classes = ['btn-' + color, 'btn-' + size, 'nowrap'];
				if (this.hidden) {
					classes.push('btn-hidden');
				}

				return classes.join(' ');
			},
			onClick(e) {
				this.$emit('click', e);
			},
			onFileChanged(e) {
				if (!e.target.files || !e.target.files.length) {
					this.title = null;
					this.filesCnt = 0;
					return;
				}

				this.title = [...e.target.files].map(file => file.name).join('\n');
				this.filesCnt = e.target.files.length;

				this.$emit('change', e);
			},
			resetFile() {
				this.title = null;
				this.filesCnt = 0;

				this.fileAvailable = false;
				this.$nextTick(() => {
					this.fileAvailable = true;
				});
			}
		}
	}
</script>

<style lang="less">
	@import "../styles/helpers";

	.rich-btn {
		display: inline-block;

		button {
			position: relative;
			border: none;
			padding: 0 12px;
			height: 30px;
			line-height: 30px;
			//color: #fff;
			color: #000;
			background: #fff;
			font-size: 14px;
			//border-radius: 3px;
			min-width: 110px;
			outline: none;
			//text-transform: uppercase;
			width: 100%;
			cursor: pointer;

			.box-shadow(0 1px 3px rgba(0, 0, 0, 0.3));
			.transition(all 0.2s);

			&.btn-yellow {
				background: #eeb111;

				&:hover {
					background: #d99e02;
				}
			}

			&.btn-green {
				cursor: pointer;
				//background: #2eb85c;
				//background: linear-gradient(#2eb85c, #2aa954);

				&:hover {
					//background: linear-gradient(#2aa954, #26994c);
				}
				&:active {
					//background: linear-gradient(#26994c, #248f47);
					top: 1px;
				}
			}

			&.btn-grey {
				cursor: pointer;
				background: #ccc;
				background: linear-gradient(#ccc, #bbb);

				&:hover {
					background: linear-gradient(#bbb, #aaa);
				}
				&:active {
					background: linear-gradient(#aaa, #999);
					top: 1px;
				}
			}
		}
	}
</style>