<template>
	<div class="dp" :data-type="calendarType" v-mousedown-outside="hideCalendar">
		<div class="dp-tf-box">
			<template v-if="win.width >= win.SM || calendarType === 'month'">
				<span v-if="!unclearable" v-show="value" class="dp-clear zmdi zmdi-close" @click="clear"></span>
				<input type="text" :value="tfText" readonly @focus="showCalendar">
			</template>
			<input v-else type="date" v-model="nativeVal" @change="onNativeValChanged">
		</div>
		<div v-if="calendarShown" class="dp-calendar">
			<div v-if="calendarType === 'day'">
				\<div class="dp-controls">
					<select v-model="month" class="dp-month">
						<option v-if="!month" value=""></option>
						<option
							v-for="(monthText, key) in months"
							:value="monthIndexToString(key)"
						>{{ monthText }}</option>
					</select>
					<input type="number" v-model.lazy="year" class="dp-year">
				</div>
				<div>
					<table>
						<thead>
						<tr>
							<th v-for="day in weekDays">{{ day }}</th>
						</tr>
						</thead>
						<tbody>
						<tr v-for="row in grid">
							<td
								v-for="cell in row"
								:class="getCellClasses(cell)"
								@click="onCellClick(cell)"
							>
								<span v-if="cell">{{ cell.day }}</span>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div v-else-if="calendarType === 'month'">
				<div class="dp-controls">
					<div>
						<input type="number" v-model.lazy="year" class="dp-year">
					</div>
				</div>
				<div>
					<table>
						<tr v-for="i in 3">
							<td
								v-for="j in 4"
								:class="getMonthCellClasses(i, j)"
								@click="onMonthClick(i, j)"
							>{{ getShortMonth(i, j) }}</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: [
			'value',
			'with-time',
			'unclearable',
			'empty-val',
			'empty-text',
			'type',
			'min-val',
			'max-val',
			'hilited-dates'
		],
		data() {
			return {
				nativeVal: null,
				calendarShown: false,
				year: null,
				month: null,
				time: '00:00',
				curDate: moment().format('YYYY-MM-DD'),
				curTime: moment().format('HH:mm'),
				minYear: 1950,
				maxYear: 2025
			};
		},
		computed: {
			weekDays() {
				this.app.store.datetimeRefresher;
				return this.getLocalizedDows();
			},
			months() {
				this.app.store.datetimeRefresher;
				return this.getLocalizedMonths();
			},
			tfText() {
				if (this.emptyVal && this.emptyText && this.value === this.emptyVal) return this.emptyText;
				if (!this.value) return this.emptyText;

				if (this.calendarType === 'month') {
					let formattedText = moment(this.value).format('MMMM YYYY');
					return formattedText || this.value;
				}
				return this.value;
			},
			val() {
				let val = this.value;
				if (!val || val === '0000-00-00') {
					val = this.curDate;
				}
				if (this.calendarType === 'month') {
					val = val.slice(0, 7);
				}
				return val;
			},
			grid() {
				if (!this.month || !this.year) return [];

				let yearMonth = this.year + '-' + this.month;
				let time = moment(yearMonth + '-01');
				let dayOfWeek = time.day() - 1;
				if (dayOfWeek < 0) {
					dayOfWeek = 6;
				}
				let daysInMonth = time.daysInMonth();

				let grid = [];
				let rowsCnt = Math.ceil((daysInMonth + dayOfWeek) / 7);
				for (let i = 0; i < rowsCnt; i++) {
					let row = [];
					for (let j = 0; j < 7; j++) {
						let cell = null;
						if (i > 0 || j >= dayOfWeek) {
							let day = i * 7 + j - dayOfWeek + 1;
							let date = yearMonth + '-' + (day < 10 ? '0' : '') + day;
							if (day <= daysInMonth) {
								cell = {day, date};
							}
						}
						row.push(cell);
					}
					grid.push(row);
				}

				return grid;
			},
			timesSuggestions() {
				let items = [];

				for (let h = 0; h < 24; h++) {
					for (let m = 0; m < 60; m += 10) {
						let time = (h < 10 ? '0' + h : h) + ':' + (m < 10 ? '0' + m : m);
						items.push({
							key: time,
							val: time
						});
					}
				}

				return items;
			},
			calendarType() {
				let types = ['day', 'month'];
				if (!this.type || types.indexOf(this.type) === -1) return types[0];
				return this.type;
			},
			minDate() {
				if (!this.minVal) return;
				let mDate = moment(this.minVal, 'YYYY-MM-DD', true);
				if (!mDate.isValid()) return;

				let mYear = mDate.year();
				if (mYear < this.minYear || mYear > this.maxYear) return;
				let maxDate = moment(this.maxVal, 'YYYY-MM-DD', true);
				if(maxDate.isValid() && mDate > maxDate) return;

				return mDate;
			},
			minimumYear() {
				if(!this.minDate) return this.minYear;
				return this.minDate.year();
			},
			minimumMonth() {
				if(!this.minDate) return 1;
				return this.minDate.month() + 1;
			},
			minimumDay() {
				if(!this.minDate) return 1;
				return this.minDate.date();
			},
			maxDate() {
				if (!this.maxVal) return;
				let mDate = moment(this.maxVal, 'YYYY-MM-DD', true);
				if (!mDate.isValid()) return;

				let mYear = mDate.year();
				if (mYear < this.minYear || mYear > this.maxYear) return;
				let minDate = moment(this.minVal, 'YYYY-MM-DD', true);
				if(minDate.isValid() && mDate < minDate) return;

				return mDate;
			},
			maximumYear() {
				if(!this.maxDate) return this.maxYear;
				return this.maxDate.year();
			},
			maximumMonth() {
				if(!this.maxDate) return 12;
				return this.maxDate.month() + 1;
			},
			maximumDay() {
				if(!this.maxDate) return 31;
				return this.maxDate.date();
			}
		},
		methods: {
			showCalendar() {
				this.updateDatetimeData();
				this.calendarShown = true;
			},
			hideCalendar() {
				this.calendarShown = false;
				this.time = '00:00';
			},
			onCellClick(cell) {
				if (!cell || cell.date === this.date) return;
				if(cell && (moment(cell.date, 'YYYY-MM-DD', true) > this.maxDate || moment(cell.date, 'YYYY-MM-DD', true) < this.minDate)) {
					return;
				}

				let val = cell.date;
				if (this.withTime) {
					val += ' ' + this.time;
				}
				this.setVal(val);
				this.hideCalendar();
			},
			onMonthClick(i, j) {
				let monIndex = this.getMonthIndex(i, j);
				if(this.isEmptyMonth(monIndex)) return;
				let mon = this.monthIndexToString(monIndex);
				let date = this.year + '-' + mon;

				this.setVal(date);
				this.hideCalendar();
			},
			updateDatetimeData() {
				if (this.val === this.emptyVal) return;

				this.year = this.val.slice(0, 4);
				this.month = this.val.slice(5, 7);
				if (this.withTime) {
					this.time = this.val.slice(11, 16) || '00:00';
				}
			},
			monthIndexToString(index) {
				let month = String(index + 1);
				if (month.length < 2) {
					month = '0' + month;
				}
				return month;
			},
			getCellClasses(cell) {
				let classes = [];
				if (cell) {
					classes.push('dp-day');
				} else {
					classes.push('dp-day-empty');
				}
				if(cell && (moment(cell.date, 'YYYY-MM-DD', true) > this.maxDate || moment(cell.date, 'YYYY-MM-DD', true) < this.minDate)) {
					classes.push('dp-disable');
				}
				if (cell && cell.date === this.curDate) {
					classes.push('today');
				}
				if (cell && cell.date === String(this.value || '').slice(0, 10)) {
					classes.push('chosen');
				}
				if (cell && this.hilitedDates && this.hilitedDates.indexOf(cell.date) !== -1) {
					classes.push('hilited');
				}
				return classes;
			},
			isEmptyMonth(i) {
				if(this.year == this.minimumYear) {
					if((i + 1) < this.minimumMonth) {
						return true;
					}
				}
				if(this.year == this.maximumYear) {
					if((i + 1) > this.maximumMonth) {
						return true;
					}
				}
				return false;
			},
			showCurrentDate() {
				return this.curDate >= this.minDate || this.curDate <= this.maxDate;
			},
			getMonthCellClasses(i, j) {
				let monIndex = this.getMonthIndex(i, j);
				let mon = this.monthIndexToString(monIndex);
				let date = this.year + '-' + mon;

				let classes = [];

				if(this.isEmptyMonth(monIndex)) {
					classes.push('dp-month-empty');
				}
				if (date === this.curDate.slice(0, 7) && this.showCurrentDate()) {
					classes.push('cur-month');
				}
				if (date === this.value) {
					classes.push('chosen');
				}
				return classes;
			},
			getShortMonth(i, j) {
				let index = this.getMonthIndex(i, j);
				return (this.months[index] || '').slice(0, 3);
			},
			getMonthIndex(i, j) {
				let index = (i - 1) * 4 + j - 1;
				return index;
			},
			setVal(date) {
				this.$emit('input', date);
				this.$emit('change', {
					isTrusted: true,
					value: date
				});
			},
			clear() {
				this.setVal(this.emptyVal || '');
				this.hideCalendar();
			},
			onNativeValChanged() {
				this.setVal(this.nativeVal);
			},
			restrictNumber(e, min, max) {
				let val = e.target.value;
				if (isNaN(val) || val === '') {
					e.target.value = '';
				} else if (val < min) {
					e.target.value = min;
				} else if (val > max) {
					e.target.value = max;
				}
			},
			fixMinMaxMonth() {
				let dateStr =  this.year + '-' + this.month + '-01';
				let date = moment(dateStr, 'YYYY-MM-DD', true);
				if (date.year() == this.minimumYear && (date.month() + 1) < this.minimumMonth) {
					let mnth = String(this.minimumMonth);
					if (mnth.length < 2) {
						this.month = '0' + mnth;
					} else this.month = mnth;
				}
				if (date.year() == this.maximumYear && (date.month() + 1) > this.maximumMonth) {
					let mnth = String(this.maximumMonth);
					if (mnth.length < 2) {
						this.month = '0' + mnth;
					} else this.month = mnth;
				}
			}
		},
		watch: {
			value() {
				this.updateDatetimeData();
				this.nativeVal = this.value;
			},
			year() {
				if (this.year < this.minimumYear) {
					this.year = this.minimumYear;
				} else if (this.year > this.maximumYear) {
					this.year = this.maximumYear;
				}
				this.fixMinMaxMonth();
			},
			month() {
				if (this.month === '00') {
					this.month = '01';
				}
				this.fixMinMaxMonth();
			}
		},
		created() {
			this.updateDatetimeData();

			this.nativeVal = this.value;
		}
	};

</script>