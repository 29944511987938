<template>
	<popup-window class="request-popup" @close="$emit('close')">
		<div slot="header">Оформление заявки</div>
		<div v-if="!tour">Загрузка...</div>
		<div v-else-if="!sent">
			<div>
				{{ tour.title }}
			</div>
			<div class="controls">
				<div>
					<i class="text-field-icon fa fa-calendar"></i>
					<select v-model="chosenDate" class="text-field">
						<option v-for="date in tour.dates" :value="date">{{ date }}</option>
					</select>
				</div>
				<div>
					<i class="text-field-icon fa fa-user-circle"></i>
					<input type="text" v-model="name" class="text-field" placeholder="Ваше имя">
				</div>
				<div>
					<i class="text-field-icon fa fa-phone"></i>
					<input type="text" v-model="contact" class="text-field" placeholder="Ваш e-mail или номер телефона">
				</div>
				<div>
					<i class="text-field-icon fa fa-commenting-o"></i>
					<textarea v-model="comment" class="text-area" placeholder="Ваши пожелания"></textarea>
				</div>
			</div>
			<div class="bottom">
				<rich-btn v-if="!isSending" @click="submit">Оформить заявку</rich-btn>
				<span v-else>Оформление заявки...</span>
			</div>
		</div>
		<div v-else>Заявка оформления. Мы свяжемся с Вами в ближайшее время</div>
	</popup-window>
</template>

<script>
	import axios from 'axios';

	export default {
		props: ['tourId', 'date'],
		data() {
			return {
				tour: null,
				chosenDate: null,
				name: '',
				contact: '',
				comment: '',
				isSending: false,
				sent: false
			};
		},
		methods: {
			async loadTour() {
				let params = {tourId: this.tourId};
				let res = await axios('/api/tour', { params });
				this.tour = res.data.res;
				this.chosenDate = this.date || this.tour.dates[0];
			},
			async submit() {
				if (!this.name) return this.alert('Пожалуйста, введите Ваше имя');
				if (!this.contact) return this.alert('Пожалуйста, введите Ваш e-mail или номер телефона');

				let body = {
					tourId: this.tourId,
					date: this.chosenDate,
					name: this.name,
					contact: this.contact,
					comment: this.comment
				};
				this.isSending = true;
				await axios.post('/api/tour/send-request', body);
				this.isSending = false;

				this.sent = true;
			}
		},
		mounted() {
			this.loadTour();
		}
	};
</script>

<style lang="less">
.request-popup {
	.controls {
		& > div {
			padding: 5px 0;
		}
		input, select, textarea {
			width: 100%;
		}
		textarea {
			height: 100px;
		}
	}
	.bottom {
		text-align: center;
	}
}
</style>