import { render, staticRenderFns } from "./RichBtn.vue?vue&type=template&id=f8f7ddf8&"
import script from "./RichBtn.vue?vue&type=script&lang=js&"
export * from "./RichBtn.vue?vue&type=script&lang=js&"
import style0 from "./RichBtn.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports