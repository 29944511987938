<template>
	<div class="drop-panel" :class="scrollable ? 'drop-panel-scrollable' : ''">
		<div class="drop-panel-clickable" @click="toggle">
			<slot></slot>
		</div>
		<transition :name="anim ? 'drop-anim' : ''">
			<div v-if="active" class="drop-panel-box" :class="boxClass" ref="box" :style="boxStyle">
				<div class="drop-panel-box-inner">
					<slot name="box"></slot>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	export default {
		props: {
			corner: {
				validator: val => ['top-left', 'bottom-left', 'top-right', 'bottom-right'].indexOf(val) !== -1,
				default: 'bottom-left'
			},
			dirX: {
				validator: val => ['left', 'right'].indexOf(val) !== -1,
				default: 'right'
			},
			dirY: {
				validator: val => ['bottom', 'top'].indexOf(val) !== -1,
				default: 'bottom'
			},
			anim: {
				validator: val => ['ltr', 'rtl', 'btt', 'ttb'].indexOf(val) !== -1
			},
			scrollable: {
				default: false
			}
		},
		data() {
			return {
				active: false,
				height: 'auto'
			};
		},
		computed: {
			boxClass() {
				let anim = (this.anim ? 'anim-' + this.anim : '');
				return [this.corner, 'dir-' + this.dirX, 'dir-' + this.dirY, anim];
			},
			boxStyle() {
				let style = {
					height: this.height
				};
				return style;
			}
		},
		methods: {
			toggle() {
				this.active = !this.active;

				if (this.active) {
					setTimeout(() => {
						document.addEventListener('click', this.onDocClick)
					}, 100);
				} else {
					document.removeEventListener('click', this.onDocClick);
				}
			},
			close() {
				if (!this.active) return;

				this.toggle();
			},
			onDocClick(e) {
				let isInside = this.$refs.box.contains(e.target);
				if (!isInside && this.active) {
					this.toggle();
				}
			}
		},
		watch: {
			active() {
				this.$emit('change', this.active);

				this.height = 'auto';

				if (!this.active || !this.scrollable) return;

				this.$nextTick(() => {
					let box = this.$refs.box;
					if (!box) return;

					let rect = box.getBoundingClientRect();
					let shift = Math.ceil(rect.bottom - this.win.height);
					if (shift > 0) {
						this.height = (rect.height - shift) + 'px';
					} else {
						this.height = 'auto';
					}
				});
			}
		},
		destroyed() {
			document.removeEventListener('click', this.onDocClick);
		}
	}
</script>

<style lang="less">
	@import "../styles/helpers";

	.drop-panel {
		display: inline-block;
		position: relative;
		background: #fff;

		.drop-anim-enter-active, .drop-anim-leave-active {
			.transition(all .1s);
		}
		.drop-anim-enter, .drop-anim-leave-to {
			opacity: 0.2;

			&.anim-btt {
				transform: translate(0, 10px);
			}
			&.anim-ttb {
				transform: translate(0, -10px);
			}
			&.anim-ltr {
				transform: translate(-10px, 0);
			}
			&.anim-rtl {
				transform: translate(10px, 0);
			}
		}

		.drop-panel-clickable {
			display: inline-block;
			width: 100%;
		}

		&.drop-panel-scrollable {
			.drop-panel-box {
				overflow-y: auto;
			}
		}

		.drop-panel-box {
			position: absolute;
			line-height: normal;
			background: #fff;
			border-radius: 3px;
			z-index: 100;
			min-width: 100%;
			max-width: 100vw;
			border: 1px solid #aaa;
			//.box-shadow(0 0 3px rgba(103,103,103,0.5));
			.scrollbar(2px);

			&.bottom-left {
				&.dir-right {
					left: 0;
				}
				&.dir-left {
					right: 100%;
				}
				&.dir-bottom {
					top: 100%;
					margin-top: -1px;
				}
				&.dir-top {
					bottom: 0;
				}
			}
			&.top-left {
				&.dir-right {
					left: 0;
				}
				&.dir-left {
					right: 100%;
				}
				&.dir-bottom {
					top: 0;
				}
				&.dir-top {
					bottom: 100%;
				}
			}
			&.top-right {
				&.dir-right {
					left: 100%;
				}
				&.dir-left {
					right: 0;
				}
				&.dir-bottom {
					top: 0;
				}
				&.dir-top {
					bottom: 100%;
				}
			}
			&.bottom-right {
				&.dir-right {
					left: 100%;
				}
				&.dir-left {
					right: 0;
				}
				&.dir-bottom {
					top: 100%;
				}
				&.dir-top {
					bottom: 0;
				}
			}
		}

		.drop-panel-box-inner {
			background: #fff;
			position: relative;
		}

		&.drop-panel-right {
			.drop-panel-box {
				right: 0;
				left: auto;
			}
		}

		.drop-panel-item {
			position: relative;

			.drop-panel-item-text {
				display: block;
				cursor: pointer;
				padding: 7px 10px;
				white-space: nowrap;
				text-transform: none;
				font-size: 13px;
				text-decoration: none !important;
				color: #000;

				&:hover {
					background: #e9f0f8;
				}

				.fa {
					margin-right: 3px;
				}
			}

			&.drop-panel-item-active .drop-panel-item-text {
				background: #e9f0f8;
			}
		}
	}
</style>