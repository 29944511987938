<template>
	<div class="calendar">
		<div class="dp-calendar">
			<div v-if="withTime" class="dp-time">
				<!--<rich-sel :items="timesSuggestions" v-model="time"></rich-sel>-->
				<input type="time" v-model.lazy="time" @change="onTimeChanged" required>
			</div>
			<div class="dp-month-picker">
				<span class="dp-month-control fa fa-chevron-left link" @click="toPrevMonth"></span>
				<span class="dp-month-control fa fa-chevron-right link" @click="toNextMonth"></span>
				<span>{{ months[month - 1] }} {{ year }}</span>
			</div>
			<!--
			<div class="dp-controls">
				<select v-model="month" class="dp-month">
					<option v-if="!month" value=""></option>
					<option
						v-for="(monthText, key) in months"
						:value="monthIndexToString(key)"
					>{{ monthText }}</option>
				</select>
				<input type="number" v-model.lazy="year" class="dp-year">
			</div>
			-->
			<div>
				<table>
					<thead>
					<tr>
						<th v-for="day in weekDays">{{ day }}</th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="row in grid">
						<td
							v-for="cell in row"
							:class="getCellClasses(cell)"
							@click="onCellClick(cell)"
						>
							<span v-if="cell">{{ cell.day }}</span>
						</td>
					</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';

	export default {
		props: ['value', 'with-time', 'unclearable', 'empty-val', 'empty-text'],
		data() {
			return {
				nativeVal: null,
				calendarShown: false,
				year: null,
				month: null,
				time: '00:00',
				curDate: moment().format('YYYY-MM-DD'),
				curTime: moment().format('HH:mm'),
				minYear: 1950,
				maxYear: 2120
			};
		},
		computed: {
			weekDays() {
				return this.getLocalizedDows();
			},
			months() {
				return this.getLocalizedMonths();
			},
			tfText() {
				if (this.emptyVal && this.emptyText && this.value === this.emptyVal) return this.emptyText;
				return this.value;
			},
			val() {
				return this.value || this.curDate;
			},
			grid() {
				if (!this.year || !this.month) return [];

				let yearMonth = this.year + '-' + this.month;

				let time = moment(yearMonth + '-01');
				let dayOfWeek = time.day() - 1;
				if (dayOfWeek < 0) {
					dayOfWeek = 6;
				}
				let daysInMonth = time.daysInMonth();

				let grid = [];
				let rowsCnt = Math.ceil((daysInMonth + dayOfWeek) / 7);
				for (let i = 0; i < rowsCnt; i++) {
					let row = [];
					for (let j = 0; j < 7; j++) {
						let cell = null;
						if (i > 0 || j >= dayOfWeek) {
							let day = i * 7 + j - dayOfWeek + 1;
							let date = yearMonth + '-' + (day < 10 ? '0' : '') + day;
							if (day <= daysInMonth) {
								cell = {day, date};
							}
						}
						row.push(cell);
					}
					grid.push(row);
				}

				return grid;
			},
			timesSuggestions() {
				let items = [];

				for (let h = 0; h < 24; h++) {
					for (let m = 0; m < 60; m += 10) {
						let time = (h < 10 ? '0' + h : h) + ':' + (m < 10 ? '0' + m : m);
						items.push({
							key: time,
							val: time
						});
					}
				}

				return items;
			}
		},
		methods: {
			showCalendar() {
				this.updateDatetimeData();
				this.calendarShown = true;
			},
			hideCalendar() {
				this.calendarShown = false;
				this.time = '00:00';
			},
			toPrevMonth() {
				let month = parseInt(this.month) - 1;
				if (month < 1) {
					month = 12;
					this.year--;
				}

				this.month = ('0' + month).slice(-2);
			},
			toNextMonth() {
				let month = parseInt(this.month) + 1;
				if (month > 12) {
					month = 1;
					this.year++;
				}

				this.month = ('0' + month).slice(-2);
			},
			getLocalizedMonths() {
				let months = [];
				for (let i = 0; i < 12; i++) {
					let month = this.util.capitalize(moment().startOf('year').add(i, 'month').format('MMMM'));
					months.push(month);
				}
				return months;
			},
			getLocalizedDows() {
				let dows = [];
				for (let i = 0; i < 7; i++) {
					let dow = this.util.capitalize(moment().startOf('isoWeek').add(i, 'day').format('dd'));
					dows.push(dow);
				}
				return dows;
			},
			onCellClick(cell) {
				if (!cell || cell.date === this.date) return;

				let val = cell.date;
				if (this.withTime) {
					val += ' ' + this.time;
				}
				this.setVal(val);
				// this.hideCalendar();
			},
			updateDatetimeData() {
				if (this.val === this.emptyVal) return;

				this.year = this.val.slice(0, 4);
				this.month = this.val.slice(5, 7);
				if (this.withTime) {
					this.time = this.val.slice(11, 16) || '00:00';
				}
			},
			monthIndexToString(index) {
				let month = String(index + 1);
				if (month.length < 2) {
					month = '0' + month;
				}
				return month;
			},
			getCellClasses(cell) {
				let classes = [];
				if (cell) {
					classes.push('dp-day');
				} else {
					classes.push('dp-day-empty');
				}
				if (cell && cell.date === this.curDate) {
					classes.push('today');
				}
				if (cell && cell.date === this.val && !this.value) {
					classes.push('chosen');
				}
				if (cell && cell.date === String(this.val || '').slice(0, 10)) {
					classes.push('chosen');
				}
				return classes;
			},
			setVal(date) {
				this.$emit('input', date);
				this.$emit('change', {
					isTrusted: true,
					value: date
				});
			},
			clear() {
				this.setVal(this.emptyVal || '');
				this.hideCalendar();
			},
			onNativeValChanged() {
				this.setVal(this.nativeVal);
			},
			restrictNumber(e, min, max) {
				let val = e.target.value;
				if (isNaN(val) || val === '') {
					e.target.value = '';
				} else if (val < min) {
					e.target.value = min;
				} else if (val > max) {
					e.target.value = max;
				}
			},
			onTimeChanged() {
				let date = this.value || this.val || '';
				let datetime = date.substr(0, 10) + ' ' + this.time;
				this.setVal(datetime);
			}
		},
		watch: {
			value() {
				this.updateDatetimeData();
				this.nativeVal = this.value;
			},
			year() {
				if (this.year) {
					if (this.year < this.minYear) {
						this.year = this.minYear;
					} else if (this.year > this.maxYear) {
						this.year = this.maxYear;
					}
				}
				else {
					this.year = moment().format('YYYY');
				}
			},
			month() {
				this.month = this.month ? this.month :moment().format('MM');
			}
		},
		created() {
			this.updateDatetimeData();

			this.nativeVal = this.value;
		}
	};
</script>

<style lang="less">
	@import "../styles/vars";

	.calendar {
		font-size: 14px;
		min-width: 210px;
		.dp-calendar {
			background: #fff;

			.dp-time {
				padding: 3px;
				border: 1px solid #ccc;

				.richsel-val-tf {
					width: 80px;
				}
			}

			.dp-month-picker {
				position: relative;
				text-align: center;

				& > * {
					line-height: 30px;
				}

				.dp-month-control {
					position: absolute;
					&:nth-child(1) {
						left: 3px;
					}
					&:nth-child(2) {
						right: 3px;
					}
				}
			}

			.dp-controls {
				position: relative;
				padding: 3px;
				height: 29px;
				border: 1px solid #ccc;
				border-bottom: none;

				.dp-month {
					position: absolute;
					left: 3px;
					width: 55%;
				}
				.dp-year {
					position: absolute;
					right: 3px;
					width: 40%;
				}

				&:after {
					content: "";
					display: block;
					clear: both;
				}
			}

			table {
				border-collapse: collapse;
				th, td {
					width: 30px;
					height: 30px;
					border: 1px solid #ccc;
					text-align: center;
				}

				th {
					font-weight: 400;
					background: #e9f0f8;
				}

				td.dp-day {
					cursor: pointer;

					&:hover, &.today {
						background: #f2f6f9;
					}

					&.chosen {
						color: #fff;
						cursor: default;
						background: mix(@primColor, #fff, 80);
						border: 1px solid mix(@primColor, #fff, 80);
					}
				}

				td.dp-day-empty {
					background: #f1f1f1;
				}
			}
		}
	}
</style>