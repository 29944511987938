export default {
	props: ['persistent', 'width'],
	data() {
		return {
			contentStyle: {},
			dndStartX: 0,
			dndStartY: 0,
			offsetLeft: 0,
			offsetTop: 0,
			shown: false
		};
	},
	computed: {
		style() {
			let style = {
				left: this.offsetLeft + 'px',
				top: this.offsetTop + 'px'
			};
			if (this.width) {
				style.width = this.width + 'px';
			}
			return style;
		}
	},
	methods: {
		onClick(e) {
			if (this.persistent) return;
			if (e.target !== this.$el) return;
			this.close('outside');
		},
		onHeaderTouchStart(e) {
			if (e.target !== e.currentTarget) return;
			if (!e.touches || !e.touches[0]) return;
			
			e.preventDefault();
			
			let touch = e.touches[0];
			
			this.dndStartY = touch.clientY - this.offsetTop;
			
			document.addEventListener('touchmove', this.onDocTouchMove);
			document.addEventListener('touchend', this.unbindDocTouchEnd);
			
			this.unbindDocMouseUp();
		},
		onDocTouchMove(e) {
			if (!e.touches || !e.touches[0]) return;
			
			let touch = e.touches[0];
			this.offsetTop = touch.clientY - this.dndStartY;
		},
		unbindDocTouchEnd(e) {
			document.removeEventListener('touchmove', this.onDocTouchMove);
			document.removeEventListener('touchend', this.unbindDocTouchEnd);
		},
		
		onHeaderMouseDown(e) {
			if (e.target !== e.currentTarget) return;
			
			e.preventDefault();
			
			this.dndStartX = e.clientX - this.offsetLeft;
			this.dndStartY = e.clientY - this.offsetTop;
			
			document.addEventListener('mousemove', this.onDocMouseMove);
			document.addEventListener('mouseup', this.unbindDocMouseUp);
			
			this.unbindDocTouchEnd();
		},
		onDocMouseMove(e) {
			this.offsetLeft = e.clientX - this.dndStartX;
			this.offsetTop = e.clientY - this.dndStartY;
		},
		unbindDocMouseUp(e) {
			document.removeEventListener('mousemove', this.onDocMouseMove);
			document.removeEventListener('mouseup', this.unbindDocMouseUp);
		},
		close(type) {
			if (type !== 'back') {
				this.app.backButtonPop();
			}
			
			this.$emit('input', false);
			this.$emit('close');
		}
	},
	mounted() {
		this.offsetLeft = 0;
		this.offsetTop = 0;
		this.app.backButtonPush(() => this.close('back'));
	},
	destroyed() {
	
	}
}
