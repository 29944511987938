<template>
	<div class="home">
		<transition name="slide">
			<carousel :slides="carouselSlides" interval="5000" decoration="1">
				<div slot="bottom" class="bottom-menu">
					<div class="bottom-menu-inner">
						<div class="bottom-menu-subinner">
							<div class="sub-header">
								<div class="sub-header-left">
									<router-link to="/tours/calendar" class="link">Календарь туров</router-link>
								</div>
								<div class="sub-header-right">
									<router-link to="/services" class="link">Услуги</router-link>
								</div>
								<div class="clear"></div>
							</div>
						</div>
					</div>
				</div>
			</carousel>
		</transition>

		<tour-search-form></tour-search-form>

		<div class="home-tours">
			<tours-sidebar></tours-sidebar>

			<div class="cats-section">
				<div class="cats-box">
					<div
						v-for="(cat, i) in cats"
						:key="i"
						class="categ"
					>
						<router-link
							:to="'/tours?category=' + cat.key"
							class="cat-image-link"
						>
							<span :style="'background-image: url(/img/' + cat.img + ')'"></span>
						</router-link>
						<div class="categ-text">
							<h4>
								<router-link :to="'/tours?category=' + cat.key">{{ cat.name }}</router-link>
							</h4>
							<div class="categ-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
							<router-link
								:to="'/tours?category=' + cat.key"
								class="categ-details"
							>Подробнее &gt;</router-link>
						</div>
					</div>
					<div class="clear"></div>
				</div>
			</div>
		</div>

		<div class="home-sections-wr">
			<div class="home-section reports-section">
				<div class="section-img-wr">
					<!--<img src="/api/image/camera-beach.jpg" class="section-bg-img" alt="">-->
				</div>
				<div class="section-content">
					<router-link to="/news">Новости и фото/видео отчеты для наших любимых туристов</router-link>
				</div>
			</div>

			<div class="home-section social-section">
				<div class="section-img-wr">
					<!--<img src="/api/image/beach-from-sky.jpg" class="section-bg-img" alt="">-->
				</div>
				<div class="section-content">
					<div>Узнай первым о новых направления, турах и скидках</div>
					<div class="social-subs">
						<a href="" class="tg-link"></a>
						<a href="" class="fb-link"></a>
						<a href="" class="ig-link"></a>
						<a href="" class="yt-link"></a>
					</div>
				</div>
			</div>

			<div class="home-section clients-section">
				<div class="section-img-wr">
					<!--<img src="/api/image/discussion.jpg" class="section-bg-img" alt="">-->
				</div>
				<div class="section-content">
					С нами путешествуют:
				</div>
			</div>

			<div class="home-section partners-section">
				<div class="section-img-wr">
					<!--<img src="/api/image/business.jpg" class="section-bg-img" alt="">-->
				</div>
				<div class="section-content">
					Наши партнеры:
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Home',
		data() {
			return {
				cats: [
					{
						key: 'ukraine',
						name: 'Туры по Украине',
						img: 'carpathians.jpg'
					},
					{
						key: 'europe',
						name: 'Туры в Европу',
						img: 'carpathians.jpg'
					},
					{
						key: 'nearest',
						name: 'Ближайшие Туры',
						img: 'nearest.jpg'
					},
					{
						key: 'popular',
						name: 'Популярные направления',
						img: 'girl.jpg'
					},
					{
						key: 'corporate',
						name: 'Корпоративные',
						img: 'carpathians.jpg'
					},
					{
						key: 'school',
						name: 'Школьные туры',
						img: 'carpathians.jpg'
					},
					{
						key: 'bus',
						name: 'Автобусные туры',
						img: 'carpathians.jpg'
					},
					{
						key: 'exclusive',
						name: 'Эксклюзив',
						img: 'exclusive.jpg'
					},
					{
						key: 'hot',
						name: 'Горящие туры',
						img: 'airport.jpg'
					}
				],
				carouselSlides: [
					{
						//img: '/api/image/city.jpg?w=800&h=200',
						img: '/img/city.jpg',
						//img: '/img/poster.jpg',
						text: 'Helping you find and book the world\'s\nbest boutique and luxury hotels',
						url: '/tour/1'
					},
					{
						//img: '/api/image/girl.jpg?w=800&h=200',
						img: '/img/lake.jpg',
						text: 'Some text',
						url: '/tour/2'
					},
					{
						img: '/img/poster.jpg',
						text: 'Какой-то текст',
						url: '/tour/3'
					},
					/*{
						img: '/api/image/girl.jpg?w=800&h=200',
						text: 'Еще один текст',
						url: '/tour/4'
					}*/
				]
			};
		}
	}
</script>

<style lang="less" src="./Home.less"></style>
