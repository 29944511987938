export default {
	data() {
		return {
			test: true,

			backButtonFns: [],
			requestPopup: null,

			topMenuItems: [
				{
					text: 'О нас',
					url: '/'
				},
				{
					text: 'Подарочный сертификат',
					url: '/'
				},
				{
					text: 'Инфолисты',
					url: '/'
				},
				{
					text: 'Оформить онлайн',
					url: '/'
				},
				{
					text: 'Как купить тур',
					url: '/'
				},
				{
					text: 'Блог',
					url: '/'
				},
				{
					text: 'Агентствам',
					url: '/'
				},
				{
					text: 'Туристам',
					url: '/'
				}
			],

			headerSearch: '',
			isSearchActive: false,

			phone: '+380 98-123-45-67',
			email: 'contact@tourism.com'
		};
	},
	methods: {
		backButtonPush(fn) {
			let frame = document.querySelector('#history-frame');
			frame.contentWindow.postMessage({tourPushState: true}, location.origin);
			this.backButtonFns.push(fn);
		},
		backButtonPop() {
			let fns = this.backButtonFns;
			fns[fns.length - 1] = null;

			history.back();
		},
		initHistoryFrame() {
			window.addEventListener('message', e => {
				if (e.data.tourPopState && this.backButtonFns && this.backButtonFns.length) {
					let fn = this.backButtonFns.pop();
					fn && fn();
				}
			});
		},
		requestTour(tourId, date) {
			this.requestPopup = { tourId, date };
		},
		onSearchClick() {
			this.isSearchActive = true;
			setTimeout(() => {
				this.$refs.headerSearch.focus();
			}, 100);
		},
		onSearchBlur() {
			this.isSearchActive = false;
		},
		onSearch() {
			let q = this.headerSearch;
			if (!q) {
				q = undefined;
			}

			this.$router.push({
				path: '/tours',
				query: {query: q}
			});
		}
	},
	watch: {
		'$route.name'() {
			window.scrollTo(0, 0);
		}
	},
	mounted() {
		this.initHistoryFrame();
	}
};