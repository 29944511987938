<template>
	<div class="tours-list" :class="compact ? 'tours-list-compact' : ''">
		<div v-for="tour in tours" class="tour">
			<router-link v-if="!compact" :to="getTourUrl(tour)">
				<img src="/img/tour-photo-small.jpg" alt="">
			</router-link>
			<div class="tour-content">
				<router-link :to="getTourUrl(tour)" class="tour-title">{{ tour.title }}</router-link>
				<div class="tour-short-desc">
					<router-link v-if="compact" :to="getTourUrl(tour)">
						<img src="/img/tour-photo-small.jpg" alt="">
					</router-link>
					Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века
				</div>
				<div class="tour-bottom">
					<router-link :to="getTourUrl(tour)">Читать далее</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['tours', 'compact'],
	methods: {
		getTourUrl(tour) {
			let url = '/tour/' + tour.tourId;
			if (tour.translit) {
				url += '/' + tour.translit;
			}
			return url;
		}
	}
};
</script>

<style lang="less">
@import "../styles/helpers";

.tours-list {
	margin: 0 auto;
	width: 800px;
	max-width: 95%;

	.tour {
		padding: 10px;
		//border: 1px solid #ccc;
		margin: 15px 0;
		min-height: 100px;
		.box-shadow(0 0 4px rgba(0, 0, 0, 0.2));

		img {
			position: absolute;
			height: 80px;
		}

		.tour-content {
			padding-left: 130px;

			a.tour-title {
				font-size: 16px;
				text-decoration: none;
				color: #000;
				font-weight: 500;
				font-family: Roboto, "Open Sans";
			}
			.tour-short-desc {
				padding: 5px 0 0 0;
			}

			.tour-bottom {
				padding: 5px 0 0;

				a {
					text-decoration: none;
					color: #497caf;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}
.tours-list-compact .tour {
	.tour-short-desc {
		font-size: 13px;

		img {
			position: relative;
			float: left;
			margin: 0 5px 0 0;
		}
	}

	.tour-content {
		padding-left: 0;
	}
}
</style>