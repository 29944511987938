import moment from 'moment';

export default {
	async alert(msg) {
		return alert(msg);
	},
	async confirm(msg) {
		return confirm(msg);
	},
	async prompt(msg, defaultText) {
		return prompt(msg, defaultText);
	},
	sleep(delay) {
		return new Promise(resolve => {
			setTimeout(() => resolve(), delay);
		});
	},
	getExtendedUrl(query) {
		return { query: { ...this.$route.query, ...query } };
	},
	tsToDate(time) {
		return moment(parseInt(time * 1000)).format('YYYY-MM-DD');
	},
	dateToHuman(date, hideCurYear = false) {
		let months = [
			'января',
			'февраля',
			'марта',
			'апреля',
			'мая',
			'июня',
			'июля',
			'августа',
			'сентября',
			'октября',
			'ноября',
			'декабря'
		];

		let [y, m, d] = date.split('-');
		let day = parseInt(d);
		let month = months[parseInt(m) - 1];
		if (hideCurYear && y === moment().format('YYYY')) return `${day} ${month}`;
		return `${day} ${month} ${y}`;
	}
};