import PopupWindow from './components/PopupWindow.vue';
import RequestPopup from './components/RequestPopup.vue';
import DatePicker from './components/DatePicker.vue';
import TourSearchForm from './components/TourSearchForm.vue';
import DropPanel from './components/DropPanel.vue';
import Calendar from './components/Calendar.vue';
import ItemsList from './components/ItemsList.vue';
import RichBtn from './components/RichBtn.vue';
import BreadCrumbs from './components/BreadCrumbs.vue';
import ToursSidebar from './components/ToursSidebar.vue';
import ToursViewTabs from './components/ToursViewTabs.vue';
import ToursList from './components/ToursList.vue';
import Carousel from './components/Carousel.vue';

export default {
	'popup-window': PopupWindow,
	'request-popup': RequestPopup,
	'date-picker': DatePicker,
	'tour-search-form': TourSearchForm,
	'drop-panel': DropPanel,
	'calendar': Calendar,
	'items-list': ItemsList,
	'rich-btn': RichBtn,
	'bread-crumbs': BreadCrumbs,
	'tours-sidebar': ToursSidebar,
	'tours-view-tabs': ToursViewTabs,
	'tours-list': ToursList,
	'carousel': Carousel,
};