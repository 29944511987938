import util from './mixin/lib/util';
import computed from './mixin/computed';
import methods from './mixin/methods';
import directives from './mixin/directives';

export default {
	data() {
		return { util };
	},
	computed,
	methods,
	directives
}