<template>
	<div class="items-list">
		<div
			v-for="item in items"
			class="list-item"
			:class="item.key === value ? 'list-item-active' : ''"
			@click="onClick(item)"
		>
			<slot name="item" :item="item">
				<span class="list-item-inner">
					<i v-if="item.icon" :class="'fa fa-fw fa-' + item.icon"></i>
					{{ item.text }}
				</span>
			</slot>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['value', 'items', 'close-parent', 'active-item'],
		methods: {
			onClick(item) {
				this.$emit('input', item.key);
				this.$emit('change', {
					list: this.$el,
					item
				});

				if (this.closeParent && this.$parent.close) {
					this.$parent.close();
				}
			}
		}
	}
</script>

<style lang="less">
	.items-list {
		background: #fff;

		.list-item {
			&.list-item-active {
				.list-item-inner {
					background: #eee;
					//outline: 1px solid #eee;
					text-decoration: none;
				}
			}

			.list-item-inner {
				display: block;
				padding: 0 10px;
				line-height: 30px;
				white-space: nowrap;
				color: #000;
				cursor: pointer;

				&:hover {
					color: #000;
					background: #eeb111;
					outline: 1px solid #eeb111;
					text-decoration: none;
				}
			}

			.drop-panel {
				display: block;
			}
		}
	}
</style>