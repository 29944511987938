import Vue from 'vue';
import App from './App.vue';
import router from './router';
import mixin from './mixin';
import components from './components';

Vue.config.productionTip = false;

Vue.mixin(mixin);

for (let key in components) {
	Vue.component(key, components[key]);
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
