let directives = {
	'mousedown-outside': {
		bind(el, binding, vNode) {
			if (typeof binding.value !== 'function') {
				const compName = vNode.context.name;
				let warn = `[Vue-mousedown-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
				if (compName) {
					warn += `Found in component '${compName}'`;
				}

				console.warn(warn);
			}

			const bubble = binding.modifiers.bubble;
			const handler = (e) => {
				if (bubble || (!el.contains(e.target) && el !== e.target)) {
					binding.value(e);
				}
			};
			el.__vueMousedownOutside__ = handler;

			document.addEventListener('mousedown', handler);
		},

		unbind(el, binding) {
			document.removeEventListener('mousedown', el.__vueMousedownOutside__);
			el.__vueMousedownOutside__ = null;
		}
	}
};

export default directives;
