import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from './views/Home.vue';
import Tours from './views/Tours.vue';
import ToursCalendar from './views/ToursCalendar.vue';
import Tour from './views/Tour.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/tours',
		name: 'Tours',
		component: Tours
	},
	{
		path: '/tours-calendar',
		redirect: '/tours/calendar'
	},
	{
		path: '/tours/calendar',
		name: 'ToursCalendar',
		component: ToursCalendar
	},
	{
		path: '/tour/:tourId',
		name: 'Tour',
		component: Tour
	},
	{
		path: '/tour/:tourId/:translit',
		name: 'Tour',
		component: Tour
	},
	/*{
		path: '/about',
		name: 'About',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/!* webpackChunkName: "about" *!/ '../views/About.vue')
	}*/
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

export default router;
