<template>
	<div class="carousel">
		<div class="carousel-inner">
			<h1 v-for="(slide, i) in slides" :class="i === slideNum ? 'active-text' : ''">
				<router-link
					v-if="slide.url"
					:to="slide.url"
					@mouseenter.native="sliderStopped = true"
					@mouseleave.native="sliderStopped = false"
				>
					<template v-for="(part, j) in slide.text.split('\n')">
						<br v-if="j > 0">
						{{ part }}
					</template>
				</router-link>
			</h1>

			<div class="poster-bg" ref="bg">
				<div v-for="slide in slides" class="poster-img-wr">
					<!--<img :src="slide.img">-->
					<div class="slide-img-div" :style="'background-image:url('+slide.img+')'"></div>
				</div>
			</div>

			<template v-if="decoration">
				<div class="fade-left"></div>
				<div class="fade-right"></div>
			</template>
			<div class="slide-left fa fa-chevron-left" @click="goToSlide(slideNum - 1)"></div>
			<div class="slide-right fa fa-chevron-right" @click="goToSlide(slideNum + 1)"></div>

			<div class="dots">
				<span
					v-for="(slide, i) in slides"
					:class="i === slideNum ? 'dot-active' : ''"
					@click="onDotClick(i)"
				></span>
			</div>
		</div>

		<slot name="bottom"></slot>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				slideNum: 0,
				timeSinceSlide: 0,
				sliderStopped: false,
				destroyed: false
			};
		},
		props: ['slides', 'interval', 'decoration'],
		methods: {
			onDotClick(i) {
				this.goToSlide(i);
			},
			goToSlide(i) {
				this.slideNum = i;
				if (this.slideNum < 0) {
					this.slideNum = this.slides.length - 1;
				} else if (this.slideNum === this.slides.length) {
					this.slideNum = 0;
				}
				this.timeSinceSlide = 0;
				if (this.$refs.bg) {
					this.$refs.bg.style.marginLeft = (this.slideNum * -window.innerWidth) + 'px';
				}
			},
			async runAutoSlides() {
				if (!this.interval || this.slides.length < 2) return;

				let step = 100;
				while (!this.destroyed) {
					await this.sleep(step);

					if (this.sliderStopped) continue;

					this.timeSinceSlide += step;
					if (this.timeSinceSlide >= this.interval) {
						this.goToSlide(this.slideNum + 1);
					}
				}
			}
		},
		async mounted() {
			this.runAutoSlides();
		},
		destroyed() {
			this.destroyed = true;
		}
	}
</script>

<style lang="less">
	@import "../styles/helpers";

	.carousel {
		width: 100%;

		.carousel-inner {
			overflow: hidden;
			position: relative;
		}

		h1 {
			position: absolute;
			color: #fff;
			width: 100%;
			margin-top: 30px;
			text-align: center;
			font-weight: 300;
			font-size: 24px;
			z-index: 1;
			text-shadow: 0 1px 8px rgba(0, 0, 0, 0.6);
			opacity: 0;
			visibility: hidden;
			.transition(all 0.3s);

			&.active-text {
				opacity: 1;
				visibility: visible;
			}

			a {
				color: #fff;
				text-decoration: none;
			}
		}

		@media (min-width: 600px) {
			h1 {
				margin-top: 60px;
				font-size: 30px;
			}
		}
		@media (min-width: 1000px) {
			h1 {
				margin-top: 100px;
				font-size: 40px;
			}
		}

		.poster-bg {
			width: 500vw;
			height: 400px;
			.transition(all 0.7s);
			transition-timing-function: cubic-bezier(0.9, 0, 0.1, 1);

			.poster-img-wr {
				display: inline-block;
				width: 100vw;
				height: 400px;
				overflow: hidden;

				@media (max-width: 600px) {
					height: 200px;
				}

				img {
					min-width: 100%;
					//max-height: 100%;
					min-height: 100%;
					opacity: 0.9;
				}
				.slide-img-div {
					width: 100%;
					height: 100%;
					background-size: cover;
					background-position: center center;
				}
			}
		}

		.fade-left, .fade-right {
			top: 0;
			position: absolute;
			width: 55px;
			height: 100%;
			background: #fff;
			opacity: 0.5;
			z-index: 1;
		}
		.fade-left {
			left: 0;
		}
		.fade-right {
			right: 0;
		}

		.slide-left, .slide-right {
			@size: 50px;

			position: absolute;
			//color: #fff;
			color: #000;
			//background: rgba(0, 0, 0, 0.6);
			background: #edb110;
			top: 50%;
			margin-top: -(@size / 2);
			font-size: 22px;
			width: @size;
			height: @size;
			line-height: @size;
			//border: 1px solid #fff;
			border-radius: (@size / 2);
			cursor: pointer;
			z-index: 10;
			.transition(all 0.2s);
			.box-shadow(0 0 20px rgba(0, 0, 0, 0.5));

			&:hover {
				background: #d99e02;
			}
		}
		.slide-left {
			left: 30px;
			padding-left: 15px;
		}
		.slide-right {
			right: 30px;
			padding-left: 17px;
		}

		.dots {
			position: absolute;
			width: 100%;
			bottom: 30px;
			text-align: center;

			span {
				@size: 10px;

				display: inline-block;
				width: @size;
				height: @size;
				color: #fff;
				margin: 0 3px;
				cursor: pointer;
				border: 1px solid #fff;
				border-radius: (@size / 2);
				background: #000;
				.box-shadow(0 0 2px #fff);

				&.dot-active {
					background: #ccc;
				}
			}
		}
	}
</style>