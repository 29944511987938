<template>
	<div class="search-form">
		<div class="search-form-inner">
			<div class="search-item item-query">
				<div class="search-item-caption">Направление</div>
				<i class="text-field-icon fa fa-search"></i>
				<input type="text" v-model="query" class="text-field" @keyup.enter="search">
			</div>
			<div class="search-item item-date">
				<div class="search-item-caption">Дата</div>
				<drop-panel anim="btt" ref="monthPanel">
					<div class="date-text">{{ beautifyMonth(month) || 'Выберите дату' }}</div>
					<!--<calendar slot="box" v-model="date" @change="onDateChange"></calendar>-->
					<items-list slot="box" v-model="month" :items="monthItems" @change="onMonthChange"></items-list>
				</drop-panel>
			</div>
			<div class="search-item item-cat">
				<div class="search-item-caption">Категория</div>
				<drop-panel anim="btt" ref="catPanel">
					<div class="cat-text">{{ category ? (categories[category] || category) : 'Выберите категорию' }}</div>
					<items-list slot="box" v-model="category" :items="categoryItems" @change="onCatChange"></items-list>
				</drop-panel>
			</div>
			<div class="search-item item-price">
				<div class="search-item-caption">Стоимость</div>
				<input type="number" v-model.lazy="priceFrom" class="text-field price-from" placeholder="От">
				<input type="number" v-model.lazy="priceTo" class="text-field price-to" placeholder="До">
			</div>
			<div class="search-item item-btn">
				<rich-btn class="search-btn" @click="search">Найти тур</rich-btn>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';

	export default {
		data() {
			return {
				query: '',
				month: null,
				category: null,
				priceFrom: null,
				priceTo: null,

				curYear: moment().format('YYYY'),

				categories: {
					'': 'Любая',
					oneday: 'Однодневные',
					weekend: 'Тур выходного дня',
					holiday: 'Праздничные туры',
					may: 'Майские праздники',
					independence: 'День независимости',
					school: 'Туры для школьников',
					carpathians: 'Тур в карпаты',
					lviv: 'Тур во Львов',
					skiing: 'Горнолыжные туры',
					chernobyl: 'Тур в Чернобыль',
					piligrim: 'Паломнические туры'
				},

				monthMap: {
					jan: 'Январь',
					feb: 'Февраль',
					mar: 'Март',
					apr: 'Апрель',
					may: 'Май',
					jun: 'Июнь',
					jul: 'Июль',
					aug: 'Август',
					sep: 'Сентябрь',
					oct: 'Октябрь',
					nov: 'Ноябрь',
					dec: 'Декабрь'
				}
			};
		},
		computed: {
			monthItems() {
				let months = [];

				let m = moment();
				let startDate = m.format('YYYY-MM') + '-01';
				let curYear = m.format('YYYY');
				for (let i = 0; i < 12; i++) {
					m = moment(startDate, 'YYYY-MM').add(i, 'months');

					let key = m.format('YYYY-MM');
					let text = this.beautifyMonth(key);

					months.push({ key, text });
				}
				return months;
			},
			categoryItems() {
				return Object.keys(this.categories).map(key => {
					let text = this.categories[key];
					return { key, text };
				});
			}
		},
		methods: {
			search() {
				let localQuery = {
					query: this.query || undefined,
					month: this.month || undefined,
					category: this.category || undefined,
					priceFrom: this.priceFrom || undefined,
					priceTo: this.priceTo || undefined
				};
				let query = { ...this.$route.query, ...localQuery };
				let path;
				if (['/tours', '/tours/calendar'].indexOf(this.$route.path) === -1) {
					path = '/tours';
				}
				this.$router.push({ path, query });
			},
			beautifyMonth(month) {
				if (!month) return '';

				let m = moment(month, 'YYYY-MM');
				let text = this.monthMap[m.format('MMM').toLowerCase()];

				let year = m.format('YYYY');
				if (year !== this.curYear) {
					text += ' ' + year;
				}
				return text;
			},
			sync() {
				this.query = this.$route.query.query;
				this.month = this.$route.query.month;
				this.category = this.$route.query.category;
				this.priceFrom = this.$route.query.priceFrom;
				this.priceTo = this.$route.query.priceTo;
			},
			onMonthChange(e) {
				this.$refs.monthPanel.close();
				this.search();
			},
			onCatChange(e) {
				this.$refs.catPanel.close();
				this.search();
			}
		},
		watch: {
			$route() {
				this.sync();
			}
		},
		mounted() {
			this.sync();
		}
	}
</script>

<style src="./TourSearchForm.less" lang="less"></style>